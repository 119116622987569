<template>
    <v-row align-content="center" justify="center" class="fill-height">
        <v-col cols="12" class="text-center">
            <h2 class="display-4 grey--text">401</h2>
        </v-col>
        <v-col cols="12" class="text-center">
            <h2 class="headline grey--text">Unauthorized</h2>
            <div class="body-2 grey--text mt-5">You don't have access to this area. <br />If you believe this is a mistake, please contact your system administrator.</div>
        </v-col>
    </v-row>
</template>

<script>
export default {
    
}
</script>

<style lang="scss" scoped>

</style>